export function nav() {
	const headerBtn = document.querySelector('.header__btn'),
				nav = document.querySelector('.nav');

	headerBtn.addEventListener('click', () => {
		const btnState = headerBtn.getAttribute('aria-expanded');

		if(btnState === 'false'){
			headerBtn.setAttribute('aria-expanded', btnState.replace(/false/, 'true'));
			nav.setAttribute('aria-hidden', nav.getAttribute('aria-hidden').replace(/true/, 'false'));
		} else {
			headerBtn.setAttribute('aria-expanded', btnState.replace(/true/, 'false'));
			nav.setAttribute('aria-hidden', nav.getAttribute('aria-hidden').replace(/false/, 'true'));
		}
	});
}
