export function productTab() {
	const tabBtn = document.querySelectorAll('.product__thumb'),
				tab = document.querySelectorAll('.product__image');
	let activeIndex = 0;

	for (let i = 0; i < tabBtn.length; i++) {
		tabBtn[i].addEventListener('click', () => {
			tabBtn[i].setAttribute('aria-selected', tabBtn[i].getAttribute('aria-selected').replace(/false/, 'true'));
			tab[i].setAttribute('aria-hidden', tab[i].getAttribute('aria-hidden').replace(/true/, 'false'));

			tabBtn[activeIndex].setAttribute('aria-selected', tabBtn[activeIndex].getAttribute('aria-selected').replace(/true/, 'false'));
			tab[activeIndex].setAttribute('aria-hidden', tab[activeIndex].getAttribute('aria-hidden').replace(/false/, 'true'));

			activeIndex = i;
		});
	}
}
