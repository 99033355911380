export function changeImg() {
	let wid = window.innerWidth,
			resizeTimer = false;

	/* change Img
	*********************************************/
	const
	changeImg = document.getElementsByClassName('change-img'),

	changeImgSp = () => {
		for (let i = 0; i < changeImg.length; i++) {
			changeImg[i].setAttribute("src", changeImg[i].getAttribute("src").replace(/_pc\./, '_sp.'));
		}
	},

	changeImgPc = () => {
		for (let i = 0; i < changeImg.length; i++) {
			changeImg[i].setAttribute("src", changeImg[i].getAttribute("src").replace(/_sp\./, '_pc.'));
		}
	},

	descriminateBp = () => {
		wid = window.innerWidth;
		if(matchMedia('(max-width: 768px)').matches){
			changeImgSp();
		} else {
			changeImgPc();
		}
	};
	descriminateBp();

	window.addEventListener('resize', () => {
		if(wid > window.innerWidth || wid < window.innerWidth){
			if (resizeTimer !== false) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(descriminateBp, 100);
		}
	});
}
