import objectFitImages from 'object-fit-images'
import smoothscrollPolyfill from 'smoothscroll-polyfill';

//import {common} from "../modules/common";
import {changeImg} from "../modules/changeImg";
import {smoothScrollVanilla} from "../modules/smoothScrollVanilla";
//import {smoothLink} from "../modules/smoothLink";
//import {pagetop} from "../modules/pagetop";
//import {scrollAnimation} from "../modules/scrollAnimation";
import {nav} from "../modules/nav";

//import {accordion} from "../modules/accordion";
//import {headerFixed} from "../modules/headerFixed";
//import {scrollCv} from "../modules/scrollCv";
import {toc} from "../modules/toc";
import {snsShare} from "../modules/snsShare";

import {productTab} from "../modules/productTab";
import {productNum} from "../modules/productNum";

import {swiper} from "../modules/swiper";

objectFitImages();
//common();
changeImg();
smoothScrollVanilla();
//smoothLink();
//pagetop();
//scrollAnimation();
nav();

//accordion();
//headerFixed();
//scrollCv();
toc();
snsShare();

productTab();
productNum();

swiper();
