export function toc() {
  const indexWrap = document.getElementById('toc');

  if (indexWrap) {
    const postContent = document.querySelector('.the_content'),  //記事本文が書かれているラッパー
          headings = postContent.querySelectorAll('h2, h3, h4'),
          indexList = document.createElement("span");
    let parentLevel = 1,
        cursorNode = indexWrap;

    indexWrap.appendChild(indexList);
    indexList.classList.add('toc__wrap');


    for (let i = 0; i < headings.length; i++) {
      const currentHeading = headings[i],
            newLevel = parseInt(currentHeading.tagName.slice(1)),
            diff = newLevel - parentLevel,
            ulNode = document.createElement('ul'),
            liNode = document.createElement('li');

      currentHeading.setAttribute('id', "index-" + i);  //リンクで飛べるようにIDをつける

      if (diff > 0) {
        const containerLiNode = cursorNode.lastChild;

        containerLiNode.appendChild(ulNode);
        console.log(i, containerLiNode)

        cursorNode = ulNode;
        parentLevel = newLevel;
      }

      if (diff < 0) {
        while (0 !== diff++) {
          cursorNode = cursorNode.parentNode.parentNode;
        }
        parentLevel = newLevel;
      }

      if (currentHeading.hasAttribute('id')) {
        const link = document.createElement('A');
        link.setAttribute('href', '#' + currentHeading.getAttribute('id'));
        link.appendChild(document.createTextNode(currentHeading.textContent))
        liNode.appendChild(link);
      } else {
        liNode.appendChild(document.createTextNode(currentHeading.textContent));
      }
      cursorNode.appendChild(liNode);
    }

    let headH = 0,
  			nowY,
  			toY;
  	const interval = 10, //スクロール処理を繰り返す間隔
  				divisor = 16, //近づく割合（数値が大きいほどゆっくり近く）
  				range = (divisor / 2) + 1, //どこまで近づけば処理を終了するか(無限ループにならないように divisor から算出)
  				links = document.querySelectorAll('a[href*="#"]');

  	for (let i = 0; i < links.length; i++) {
  		links[i].addEventListener('click', (e) => {
  			e.preventDefault();

  			nowY = window.pageYOffset; //現在のスクロール値

    		const href = e.target.getAttribute('href'), //href取得
  						target = document.querySelector(href), //リンク先の要素（ターゲット）取得
  						targetRect = target.getBoundingClientRect(), //ターゲットの座標取得
  						targetY = targetRect.top + nowY - headH; //現在のスクロール値 & ヘッダーの高さを踏まえた座標

  			//スクロール終了まで繰り返す処理
  			const doScroll = () => {
  				toY = nowY + Math.round((targetY - nowY) / divisor);  //次に移動する場所（近く割合は除数による。）
  				window.scrollTo(0, toY); //スクロールさせる
  				nowY = toY; //nowY更新

  				if (document.body.clientHeight - window.innerHeight < toY) {
  					//最下部にスクロールしても対象まで届かない場合は下限までスクロールして強制終了
  					window.scrollTo(0, document.body.clientHeight);
  					return;
  				}
  				if (toY >= targetY + range || toY <= targetY - range) {
  					//+-rangeの範囲内へ近くまで繰り返す
  					window.setTimeout(doScroll, interval);
  				} else {
  					//+-range の範囲内にくれば正確な値へ移動して終了。
  					window.scrollTo(0, targetY);
  				}
  			}

  			doScroll();
  		});
  	}
  }
}
