export function productNum() {
	const productNum = document.querySelector('input[name="product_num"]');

	if (document.getElementsByClassName('product').length){
		productNum.insertAdjacentHTML('beforebegin', '<button type="button" class="product__remove"><img src="/wp-content/themes/jf-sasu/img/icn_remove.svg" alt="購入数を減らす"></button>');
		productNum.insertAdjacentHTML('afterend', '<button type="button" class="product__add"><img src="/wp-content/themes/jf-sasu/img/icn_add.svg" alt="購入数を増やす"></button>');

		const remove = document.querySelector('.product__remove'),
					add = document.querySelector('.product__add');

		add.addEventListener('click', () => {
			const current_value = productNum.value,
						plus_value = +current_value + 1;

			productNum.value = plus_value;
		});

		remove.addEventListener('click', () => {
			const current_value = productNum.value,
						minus_value = current_value - 1;

			if(minus_value >= 1) {
				productNum.value = minus_value;
			}
		});
	}
}
