export function swiper() {
	if(document.getElementsByClassName('top-mv').length){
		const topSlide = {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true
			},
		  loop: true,
			speed: 2000,
			slidesPerView: 1.5,
			spaceBetween: 20,
			centeredSlides: true,
			navigation: {
		    nextEl: '.swiper-button-next',
		    prevEl: '.swiper-button-prev',
		  },
			breakpoints: {
				769: {
		      slidesPerView: 4.5,
		      spaceBetween: 20
		    },
		  },
		};

		new Swiper('.top-fisherman__wrap', topSlide);
	}

	if(document.getElementsByClassName('top-mv__image').length > 1){
		const fadeSlide = {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true
			},
		  loop: true,
			speed: 2000,
			effect: 'fade',
			pagination: {
		    el: '.top-mv__pagination',
		    type: 'bullets',
				clickable: true,
		  },
		};

		new Swiper('.top-mv__wrap', fadeSlide);
	}

	// if(document.getElementsByClassName('l-front').length){
	// 	const linerSlide = {
	// 		autoplay: {
	// 			delay: 0,
	// 			disableOnInteraction: true
	// 		},
	// 	  loop: true,
	// 		speed: 8000,
	// 	  autoHeight: false,
	// 		slidesPerView	: 3.5,
	// 		centeredSlides: true,
	// 		spaceBetween: 21,
	// 		breakpoints: {
	// 			760: {
	// 	      slidesPerView: 1.5,
	// 	      spaceBetween: 20
	// 	    },
	// 	  },
	// 	};
	//
	// 	new Swiper('.xxx-2__wrap', linerSlide);
	//
	// 	// 画面幅によってスライドon/off
	// 	let xxxxxSlide, xxxxxSwiperBool;
	//
	// 	window.addEventListener('load',()=>{
	// 		if(matchMedia('(max-width: 760px)').matches){
	// 	    createSwiper();
	// 	    xxxxxSwiperBool = false;
	// 	  } else {
	// 			xxxxxSwiperBool = true;
	// 	  }
	// 	},false);
	//
	// 	window.addEventListener('resize',()=>{
	// 		if(matchMedia('(max-width: 760px)').matches && xxxxxSwiperBool){
	// 			createSwiper();
	// 			xxxxxSwiperBool = false;
	// 	  } else if(matchMedia('(min-width: 761px)').matches && !(xxxxxSwiperBool)) {
	// 			xxxxxSlide.destroy(false,true);
	// 	    xxxxxSwiperBool = true;
	// 	  }
	// 	},false);
	//
	// 	const createSwiper = () =>{
	// 	  xxxxxSlide = new Swiper('.front-news__wrap', {
	// 			autoplay: {
	// 				delay: 4000,
	// 				disableOnInteraction: true
	// 			},
	// 			loop: true,
	// 			speed: 2000,
	// 			autoHeight: false,
	// 			slidesPerView	: 1.25,
	// 			centeredSlides: true,
	// 			spaceBetween: 40,
	// 	  });
	// 	}
	// }
}
